import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/userdetail',
    name: 'userdetail',
    component: () => import('../views/userdetail.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    props({ query }) {
      return { code: query.code} //用组件参数的形式把query传递给组件
    },
  },
  {
    path: '/prdmain',
    name: 'prdmain',
    meta: { title: '生产进度' },
    component: () => import('../views/prdmain.vue')
  },
  {
    path: '/equmain',
    name: 'equmain',
    meta: { title: '设备管理' },
    component: () => import('../views/equmain.vue')
  },
  {
    path: '/moldmain',
    name: 'moldmain',
    meta: { title: '模具管理' },
    component: () => import('../views/moldmain.vue')
  },
  {
    path: '/moreport',
    name: 'moreport',
    meta: { title: '工序报工' },
    component: () => import('../views/moreport.vue')
  },
  {
    path: '/moreportlist',
    name: 'moreportlist',
    meta: { title: '报工记录' },
    component: () => import('../views/moreportlist.vue')
  },
  {
    path: '/moreportdetail',
    name: 'moreportdetail',
    meta: { title: '工序汇报详细' },
    component: () => import('../views/moreportdetail.vue'),
    props({ query }) {
      return { billno: query.billno} //用组件参数的形式把query传递给组件
    },
  },
  {
    path: '/prdinspect',
    name: 'prdinspect',
    meta: { title: '生产检验' },
    component: () => import('../views/prdinspect.vue')
  },
  {
    path: '/prdinspectlist',
    name: 'prdinspectlist',
    meta: { title: '生产检验记录' },
    component: () => import('../views/prdinspectlist.vue')
  },
  {
    path: '/inspect',
    name: 'inspect',
    meta: { title: '来料检验' },
    component: () => import('../views/inspect.vue'),
    props({ query }) {
      return { qrcode:query.qrcode} //用组件参数的形式把query传递给组件
    },
  },
  {
    path: '/inspectlist',
    name: 'inspectlist',
    meta: { title: '来料检验记录' },
    component: () => import('../views/inspectlist.vue')
  },
  {
    path: '/equdjtask',
    name: 'equdjtask',
    meta: { title: '设备点检' },
    component: () => import('../views/equdjtask.vue')
  },
  {
    path: '/equdjtasklist',
    name: 'equdjtasklist',
    meta: { title: '点检记录' },
    component: () => import('../views/equdjtasklist.vue')
  },
  {
    path: '/equbytask',
    name: 'equbytask',
    meta: { title: '设备保养' },
    component: () => import('../views/equbytask.vue')
  },
  {
    path: '/equbytasklist',
    name: 'equbytasklist',
    meta: { title: '保养记录' },
    component: () => import('../views/equbytasklist.vue')
  },
  {
    path: '/equwxtask',
    name: 'equwxtask',
    meta: { title: '设备维修' },
    component: () => import('../views/equwxtask.vue')
  },
  {
    path: '/equhistory',
    name: 'equhistory',
    meta: { title: '设备履历' },
    component: () => import('../views/equhistory.vue')
  },
  {
    path: '/equwxtasklist',
    name: 'equwxtasklist',
    meta: { title: '保养记录' },
    component: () => import('../views/equwxtasklist.vue')
  },
  {
    path: '/moldbytask',
    name: 'moldbytask',
    meta: { title: '模具保养' },
    component: () => import('../views/moldbytask.vue')
  },
  {
    path: '/moldbytasklist',
    name: 'moldbytasklist',
    meta: { title: '保养记录' },
    component: () => import('../views/moldbytasklist.vue')
  },
  {
    path: '/moldwxtask',
    name: 'moldwxtask',
    meta: { title: '模具保养' },
    component: () => import('../views/moldwxtask.vue')
  },
  {
    path: '/moldwxtasklist',
    name: 'moldwxtasklist',
    meta: { title: '保养记录' },
    component: () => import('../views/moldwxtasklist.vue')
  },
  {
    path: '/moldoutstock',
    name: 'moldoutstock',
    meta: { title: '模具领用' },
    component: () => import('../views/moldoutstock.vue')
  },
  {
    path: '/moldoutstocklist',
    name: 'moldoutstocklist',
    meta: { title: '领用记录' },
    component: () => import('../views/moldoutstocklist.vue')
  },
  {
    path: '/moldinstock',
    name: 'moldinstock',
    meta: { title: '模具入库' },
    component: () => import('../views/moldinstock.vue')
  },
  {
    path: '/moldinstocklist',
    name: 'moldinstocklist',
    meta: { title: '入库记录' },
    component: () => import('../views/moldinstocklist.vue')
  },
  {
    path: '/moldhistory',
    name: 'moldhistory',
    meta: { title: '模具履历' },
    component: () => import('../views/moldhistory.vue')
  },
  {
    path: '/sopdetail',
    name: 'sopdetail',
    meta: { title: 'SOP文件' },
    component: () => import('../views/sopdetail.vue'),
    props({ query }) {
      return { mnumber: query.mnumber,pnumber:query.pnumber,qrcode:query.qrcode} //用组件参数的形式把query传递给组件
    },
  },
  {
    path: '/soplist',
    name: 'soplist',
    meta: { title: '查询SOP' },
    component: () => import('../views/soplist.vue'),
    props({ query }) {
      return { qrcode:query.qrcode} //用组件参数的形式把query传递给组件
    },
  },
  {
    path: '/inspectsop',
    name: 'inspectsop',
    meta: { title: 'SOP文件' },
    component: () => import('../views/inspectsop.vue'),
    props({ query }) {
      return { mnumber: query.mnumber,qrcode:query.qrcode} //用组件参数的形式把query传递给组件
    },
  },
  {
    path: '/abnormal',
    name: 'abnormal',
    meta: { title: '异常申报' },
    component: () => import('../views/abnormal.vue')
  },
  {
    path: '/abnormallist',
    name: 'abnormallist',
    meta: { title: '异常申报记录' },
    component: () => import('../views/abnormallist.vue')
  },
  {
    path: '/abnormaldetail',
    name: 'abnormaldetail',
    meta: { title: '异常申报' },
    component: () => import('../views/abnormaldetail.vue'),
    props({ query }) {
      return { billno: query.billno} 
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
