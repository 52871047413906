<template>
 <div class="wrap">
        <div class="indexcon">
            <div class="indextitle"><span><a @click="onClick('/userdetail')">我的</a></span>金辰轻MES<i id="rolename"></i></div>
            <div class="indexadpic"><img src="../images/banner.jpg" /></div>
            <div class="indexiconlist">
                <ul>
                    <li @click="onClick('/prdmain')">
                        <div class="img"><img src="../images/iicon1.png" height="50px" /></div>
                        <div class="title">生产过程</div>
                    </li>
                    <li  @click="onClick('/equmain')" >
                        <div class="img"><img src="../images/iicon2.png" height="50px" /></div>
                        <div class="title">设备管理</div>
                    </li>
                    <li  @click="onClick('/moldmain')">
                        <div class="img"><img src="../images/iicon3.png" height="50px" /></div>
                        <div class="title">模具管理</div>
                    </li>
                    <!-- <li @click="onClick('/examreportlist')">
                        <div class="img"><img src="../images/iicon2.png" height="50px" /></div>
                        <div class="title">检验记录</div>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup> 
import '../css/main.css'
import { useRouter } from 'vue-router'; //引入路由
const _router = useRouter();
const onClick=(path)=>{
    console.log(path);
    _router.push({path:path});
    //_router.push({path:'/about',query : {id:item.FID,name:item.FProdBillNo} });
}
</script>

